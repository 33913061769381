import React from 'react';

import AddLocationIcon from '@material-ui/icons/AddLocation';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ReportIcon from '@material-ui/icons/Report';
import FlagIcon from '@material-ui/icons/Flag';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import StarsIcon from '@material-ui/icons/Stars';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import BuildIcon from '@material-ui/icons/Build';
import ErrorIcon from '@material-ui/icons/Error';
import NearMeIcon from '@material-ui/icons/NearMe';
import RoomIcon from '@material-ui/icons/Room';
import WhereToVoteIcon from '@material-ui/icons/WhereToVote';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SettingDropSelector } from './SpreadsheetSettingSharedComponents';
import { makeStyles } from '@material-ui/core/styles';

import beenHere from '../../assets/mapIcons/beenHere.png';
import addLocation from '../../assets/mapIcons/addLocation.png';
import checkCircle from '../../assets/mapIcons/checkCircle.png';
import editLocation from '../../assets/mapIcons/editLocation.png';
import localOffer from '../../assets/mapIcons/localOffer.png';
import personPinCircle from '../../assets/mapIcons/personPinCircle.png';
import addCircle from '../../assets/mapIcons/addCircle.png';
import report from '../../assets/mapIcons/report.png';
import flag from '../../assets/mapIcons/flag.png';
import accessibility from '../../assets/mapIcons/accessibility.png';
import stars from '../../assets/mapIcons/stars.png';
import priorityHigh from '../../assets/mapIcons/priorityHigh.png';
import build from '../../assets/mapIcons/build.png'
import error from '../../assets/mapIcons/error.png';
import nearMe from '../../assets/mapIcons/nearMe.png';
import room from '../../assets/mapIcons/room.png';
import whereToVote from '../../assets/mapIcons/whereToVote.png';
import camera from '../../assets/mapIcons/camera.png';
import aed from '../../assets/mapIcons/heart-attack.png';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons';

const iconsObject = {
  beenHere: {
    image: beenHere,
    icon: <><BeenhereIcon /> Check Badge</>
  },
  addLocation: {
    image: addLocation,
    icon: <><AddLocationIcon /> Plus Pin</>
  },
  checkCircle: {
    image: checkCircle,
    icon: <><CheckCircleIcon /> Check Circle</>
  },
  editLocation: {
    image: editLocation,
    icon: <><EditLocationIcon /> Edit Pin</>
  },
  localOffer: {
    image: localOffer,
    icon: <><LocalOfferIcon /> Tag Marker</>
  },
  personPinCircle: {
    image: personPinCircle,
    icon: <><PersonPinCircleIcon /> Person Pin</>
  },
  addCircle: {
    image: addCircle,
    icon: <><AddCircleIcon /> Plus Circle</>
  },
  report: {
    image: report,
    icon: <><ReportIcon /> Exclamation Octogon</>
  },
  flag: {
    image: flag,
    icon: <><FlagIcon /> Flag Marker</>
  },
  accessibility: {
    image: accessibility,
    icon: <><AccessibilityIcon /> Person Marker</>
  },
  stars: {
    image: stars,
    icon: <><StarsIcon /> Star Circle</>
  },
  priorityHigh: {
    image: priorityHigh,
    icon: <><PriorityHighIcon /> Exclamation Marker</>
  },
  build: {
    image: build,
    icon: <><BuildIcon /> Wrench Marker</>
  },
  error: {
    image: error,
    icon: <><ErrorIcon /> Exclamation Circle</>
  },
  nearMe: {
    image: nearMe,
    icon: <><NearMeIcon /> Arrow Marker</>
  },
  room: {
    image: room,
    icon: <><RoomIcon /> Circle Pin</>
  },
  whereToVote: {
    image: whereToVote,
    icon: <><WhereToVoteIcon /> Check Pin</>
  },
  camera: {
    image: camera,
    icon: <><PhotoCameraIcon /> Camera</>
  },
  aed: {
    image: aed,
    icon: <><FontAwesomeIcon icon={faHeartbeat} /> AED</>
  }
}

export const iconArray = Object.keys(iconsObject).map(key => {
  return {
    value: key,
    label: iconsObject[key].icon
  }
});

export const addIconsToMap = (map) => {
  for (let marker in iconsObject) {
    const { image } = iconsObject[marker]
    map.loadImage(image, (error, image) => {
      if (error) throw error;
      if (!map.hasImage(marker)) map.addImage(marker, image, {
        sdf: true,
        pixelRatio: 2,
      })
    })
  }
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: 0,
    minWidth: 80,
    maxWidth: '100%',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    whiteSpace: 'revert',
    overflowWrap: 'break-word'
  },

}));

export default function SharedIconSelector(props) {
  const classes = useStyles();

  const handleSelection = (icon) => {
    props.handleSelection(icon)
  }

  return (
    <SettingDropSelector
      label="Select Icon"
      formControllClass={classes.formControl}
      value={props.value}
      handleSelection={handleSelection}
      labelIcon={<AddLocationIcon />}
      active={props.active}
      selectClass={classes.menuItem}
      menuItemClass={classes.menuItem}
      fullWidth={false}
      optionsArray={iconArray}
      defaultOption={{
        label: "No Icon",
        value: ''
      }}
    />
  );
}