import React from "react"
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import OpacityIcon from '@material-ui/icons/Opacity';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import FormatColorTextIcon from '@material-ui/icons/FormatColorText';
import FormatSizeIcon from '@material-ui/icons/FormatSize';

export const settingLookup = {
  'fillColor': {
    text: 'Fill Color',
    icon: <FormatColorFillIcon size="small" style={{ marginRight: 10 }} />,
    defaultValue: 'red',
  },
  'fillOpacity': {
    text: 'Fill Opacity',
    icon: <OpacityIcon size="small" style={{ marginRight: 10 }} />,
    defaultValue: 1,
    min: 0,
    max: 1,
    step: .05
  },
  'lineColor': {
    text: 'Border Color',
    icon: <BorderColorIcon size="small" style={{ marginRight: 10 }} />,
    defaultValue: 'orange',
  },
  'lineWidth': {
    text: 'Border Thickness',
    icon: <LineWeightIcon size="small" style={{ marginRight: 10 }} />,
    defaultValue: 3,
    min: 0,
    max: 10,
    step: .5
  },
  'textField': {
    text: 'Text Field',
    icon: <TextFormatIcon size="small" style={{ marginRight: 10 }} />,
    defaultValue: '',
  },
  'textColor': {
    text: 'Text Color',
    icon: <FormatColorTextIcon size="small" style={{ marginRight: 10 }} />,
    defaultValue: 'black',
  },
  'textSize': {
    text: 'Text Size',
    icon: <FormatSizeIcon size="small" style={{ marginRight: 10 }} />,
    defaultValue: 16,
    min: 8,
    max: 24,
    step: 1,
  }
}