import React, { useState } from 'react';
import clsx from 'clsx';
import { SwatchesPicker } from 'react-color';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: 0,
    minWidth: 80,
  }, 
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  defaultComponents: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingTop: 0
  },
  settingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    alignItems: 'center',
    minHeight: 50,
    margin: 5,
  },
  settingIcon: {
    width: '10%'
  },
  settingLabel: {
    width: '35%',
  },
  settingDisabled: {
    opacity: '50%'
  },
  settingController: {
    width: '45%',
    marginRight: 10,
  },
  advancedComponents: {

  },
  widthIcon: {
    transform: 'rotate(90deg)'
  },
  sizeIcon: {
    transform: 'rotate(45deg)',
  }
}));


export const Setting = ({label, labelIcon, controller, active = true}) => {
  const classes = useStyles();

  return (
    <Box className={classes.settingContainer}>
      <Box className={clsx(classes.settingIcon, {
        [classes.settingDisabled]: !active,
      })}>
        {labelIcon}
      </Box>
      <Box className={clsx(classes.settingLabel, {
        [classes.settingDisabled]: !active,
      })}>
        {label}
      </Box>
      <Box className={classes.settingController}>
        {controller}
      </Box>
    </Box>
  );
};

export const SettingSlider = (props) => {
  const {
    handleNewValue,
    min,
    max,
    step,
    label,
    active = true,
    labelIcon,
    value,
    track,
    valueLabelDisplay
  } = props;


  const handleChange = (_event, newValue) => {
    handleNewValue(newValue)
  };
  
  return (
    <Setting 
      label={label}
      active={active}
      labelIcon={labelIcon}
      controller={
        <Slider
          disabled={!active}
          value={value}
          onChange={handleChange}
          valueLabelDisplay={valueLabelDisplay}
          min={min}
          max={max}
          step={step}
          track={track}
        />
      }
    />
  );
};

export const CollapseHeading = (props) => {
  const { headingClass, expanded, setExpanded, headingText} = props;
  const classes = useStyles();
  
  return (
    <Typography
      component="div"
      className={headingClass}
      onClick={() => setExpanded(!expanded)}
    >
      {headingText}
      <IconButton
        className={clsx(classes.expand, {
          [classes.expandOpen]: expanded,
        })}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </IconButton>
    </Typography>
  );
};

export const SettingColorSelector = (props) => {
  const { fillColor, label, active, labelIcon, handlePickerClose } = props;
  const [newColor, setNewColor] = useState('')
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    handlePickerClose(newColor)
  }

  return (
    <Setting
      active={active} 
      label={label}
      labelIcon={labelIcon}
      controller={
        <>
          <Button
            disabled={!active}
            variant="contained"
            style={active ? {
              backgroundColor: fillColor,
              borderWidth: 5,
              borderStyle: 'solid',
              borderColor: '#e0e0e0',
              padding: 10,
              width: 40,
              height: 40,
            } : {
              backgroundColor: fillColor,
              width: 40,
              height: 40,
            }}
            onClick={
              (e) => setAnchorEl(e.currentTarget)
            }
          >{''}</Button>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <SwatchesPicker
              color={newColor}
              onChangeComplete={color => setNewColor(color)}
            />
          </Popover>
        </>
      }
    />
  );
};


export const SettingDropSelector = (props) => {
  const { 
    active,
    label,
    labelIcon,
    formControlClass,
    selectClass,
    menuItemClass,
    value,
    handleSelection,
    optionsArray,
    defaultOption,
    fullWidth = true
  } = props;

  const handleChange = (event) => {
    handleSelection(event.target.value)
  };

  return (
    <Setting
      active={active}
      label={label}
      labelIcon={labelIcon}
      controller={
        <FormControl
          disabled={!active}
          className={formControlClass}
          fullWidth={fullWidth}
        >
          <Select
            classes={{
              select: selectClass,
              selectMenu: menuItemClass
            }}
            id="select-layer-text"
            value={value}
            onChange={handleChange}
            displayEmpty
          >
            <MenuItem
              value={defaultOption.value}
              disabled={defaultOption.disabled}
            >{defaultOption.label}</MenuItem>
            {optionsArray.map((option, i) => {
              return <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
            })}
          </Select>
        </FormControl>
      }
    />
  );
}