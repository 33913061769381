import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { isObject } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTypography-overline': {
      lineHeight: 1.25,
      letterSpacing: '0.03em'
    }
  },
  table: {
    marginBottom: 16,
    lineHeight: 1.1,
  },
  actionIcon: {
    padding: 2,
    marginRight: 2,
    marginTop: -3,
    fontSize: '1rem',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    }
  },
}));

export default function CustomInfo() {
  const { byId } = useSelector(state => state.sharedDatasetReducer.files)
  const { rmrecnbr, id } = useSelector(state => state.sharedDatasetReducer.selected)
  const classes = useStyles();
  const { data: arr } = byId[id];
  const headers = arr && arr.length > 0 && arr[0] && isObject(arr[0]) && Object.keys(arr[0]);
  const rrnKey = headers.find(x => ['rrn', 'rmrecnbr'].includes(x.trim().toLowerCase()));
  const results = arr.filter(x => String(x[rrnKey]).trim() === String(rmrecnbr).trim());

  return (
    <div className={classes.root}>
      {results.map(result => {
        return Object.keys(result).map((key, i) => {
          return (
            <React.Fragment key={i}>
              <Grid container spacing={0} className={classes.table}>
                <Grid item xs={12}>
                  <Typography variant="overline" style={{ fontWeight: 'bold' }}>{key}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline">{result[key]}</Typography>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        })
      })}

    </div>
  );
}