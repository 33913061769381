import { isEmpty } from "lodash";
import { settingLookup } from "./sharedDatasetDefaultData";
import { getDataHeaders } from "./sharedDatasetUtils";

export const handleSharedData = (map, files, settings, conditions, options) => {
  if (!map) return;
  const { allIds: filesAllIds, byId: filesById } = files;

  filesAllIds.forEach(fileId => {
    if (
      filesById[fileId].syncStatus === "synced" &&
      filesById[fileId].displayStatus === 'displayed'
    ) {
      handleSyncedFile(map, fileId, filesById[fileId], settings, conditions, options);
    } else {
      map
        .removeLayer(`shared-fill-${fileId}`)
        .removeLayer(`shared-line-${fileId}`)
        .removeLayer(`shared-symbol-${fileId}`)
    }
  });
}

const handleSyncedFile = (map, id, file, settings, conditions, options) => {

  const settingObj = {};
  const conditionObj = {
    fillColor: {},
    fillOpacity: {},
    lineColor: {},
    lineWidth: {},
    textField: {},
    textColor: {},
    textSize: {},
  };
  settings.allIds.forEach(settingId => {
    const { settingType, settingValue, sheetId } = settings.byId[settingId]
    if (sheetId !== id) return;
    settingObj[settingType] = settingValue;
  });
  conditions.allIds.forEach(conditionId => {

    const { settingType, settingValue, conditionKey, conditionValue, sheetId, conditionType } = conditions.byId[conditionId];
    if (sheetId !== id) return;
    /*
      conditionObj: {
        [settingType]: {
          conditionKey,
          conditionType,
          conditionArray: []
        }
      }
    */

    conditionObj[settingType].conditionKey = conditionKey;
    conditionObj[settingType].conditionType = conditionType;
    conditionObj[settingType].conditionArray = conditionObj[settingType].conditionArray ? 
      [...conditionObj[settingType].conditionArray, {settingValue, conditionValue}] : [{settingValue, conditionValue}]
  });

  const { floor, singleBuildingMode } = options;
  const { data, visibility } = file;

  const headers = getDataHeaders(data);
  const rrnKey = headers.find(x => ['rrn', 'rmrecnbr'].includes(x.trim().toLowerCase()));

  const rrns = data.map(x => x[rrnKey]);
  const rrnFilter = returnRrnFilter(rrns);
  // Todo use defaultState values
  const floorFilter = ['==', 'floor', floor || '01']
  // Todo handle single building mode
  const combinedFilter = [
    'all',
    rrnFilter,
    floorFilter
  ]

  // todo handle default from data object

  const textField = settingObj.textField ? settingObj.textField : '';
  // const xp = returnTextMapExpression(data, textField, rrnKey)
  const xp = returnTextMapExpression(settingObj, conditionObj, data, rrnKey)
  const fillColor = returnExpression(settingObj, conditionObj, data, rrnKey, 'fillColor');
  const fillOpacity = returnExpression(settingObj, conditionObj, data, rrnKey, 'fillOpacity');
  const lineColor = returnExpression(settingObj, conditionObj, data, rrnKey, 'lineColor');
  const lineWidth = returnExpression(settingObj, conditionObj, data, rrnKey, 'lineWidth');
  const textColor = returnExpression(settingObj, conditionObj, data, rrnKey, 'textColor');
  const textSize = returnExpression(settingObj, conditionObj, data, rrnKey, 'textSize');


  const FILL_LAYER_ID = `shared-fill-${id}`;
  const LINE_LAYER_ID = `shared-line-${id}`;
  const SYMBOL_LAYER_ID = `shared-symbol-${id}`;

  if (!map.getLayer(FILL_LAYER_ID)) {
    // add
    map.addLayer({
      'filter': combinedFilter,
      'source': 'campus-room',
      'source-layer': 'room',
      'type': 'fill',
      'id': FILL_LAYER_ID,
      'minzoom': 17,
      'maxzoom': 24,
      'paint': {
        'fill-color': fillColor
      },
      'layout': {
        'visibility': visibility ? 'visible' : 'none'
      }
    }, 'umich-reference')
  } else {
    const mapFillColor = map.getPaintProperty(FILL_LAYER_ID, 'fill-color');
    if (fillColor !== mapFillColor) {
      map.setPaintProperty(FILL_LAYER_ID, 'fill-color', fillColor);
    }
    const mapFillOpacity = map.getPaintProperty(FILL_LAYER_ID, 'fill-opacity');
    if (fillOpacity !== mapFillOpacity) {
      map.setPaintProperty(FILL_LAYER_ID, 'fill-opacity', fillOpacity);
    }
    const mapVisibility = map.getLayoutProperty(FILL_LAYER_ID, 'visibility') === 'visible';
    if (visibility !== mapVisibility) {
      map.setLayoutProperty(FILL_LAYER_ID, 'visibility', visibility ? 'visible' : 'none')
    }
  }

  if (!map.getLayer(LINE_LAYER_ID)) {
    // add
    map.addLayer({
      'filter': combinedFilter,
      'source': 'campus-room',
      'source-layer': 'room',
      'type': 'line',
      'id': LINE_LAYER_ID,
      'minzoom': 17,
      'maxzoom': 24,
      'layout': {
        'visibility': visibility ? 'visible' : 'none'
      }
    }, 'umich-reference')
  } else {
    const mapLineColor = map.getPaintProperty(LINE_LAYER_ID, 'line-color');
    if (lineColor !== mapLineColor) {
      map.setPaintProperty(LINE_LAYER_ID, 'line-color', lineColor);
    }
    const mapLineWidth = map.getPaintProperty(LINE_LAYER_ID, 'line-width');
    if (lineWidth !== mapLineWidth) {
      map.setPaintProperty(LINE_LAYER_ID, 'line-width', lineWidth);
    }
    const mapVisibility = map.getLayoutProperty(LINE_LAYER_ID, 'visibility') === 'visible';
    if (visibility !== mapVisibility) {
      map.setLayoutProperty(LINE_LAYER_ID, 'visibility', visibility ? 'visible' : 'none');
    }
  }

  if (!map.getLayer(SYMBOL_LAYER_ID)) {
    // todo sync font with default
    map.addLayer({
      'filter': combinedFilter,
      'source': 'campus-label',
      'source-layer': 'label',
      'type': 'symbol',
      'id': SYMBOL_LAYER_ID,
      'minzoom': 17,
      'maxzoom': 24,
      'layout': {
        'text-field': textField,
        'visibility': visibility ? 'visible' : 'none'
      },
      'metadata': {
        'key-text-field': ""
      }
    }, 'umich-reference')
  } else {
    const mapVisibility = map.getLayoutProperty(SYMBOL_LAYER_ID, 'visibility') === 'visible';
    if (visibility !== mapVisibility) {
      map.setLayoutProperty(SYMBOL_LAYER_ID, 'visibility', visibility ? 'visible' : 'none');
    }
    const mapTextField = map.getLayer(SYMBOL_LAYER_ID).metadata['key-text-field'];
    if (textField !== mapTextField) {
      map.getLayer(SYMBOL_LAYER_ID).metadata['key-text-field'] = textField;
      map.setLayoutProperty(SYMBOL_LAYER_ID, 'text-field', xp);
    }
    const mapTextColor = map.getPaintProperty(SYMBOL_LAYER_ID, 'text-color');
    if (textColor !== mapTextColor) {
      map.setPaintProperty(SYMBOL_LAYER_ID, 'text-color', textColor);
    }
    const mapTextSize = map.getLayoutProperty(SYMBOL_LAYER_ID, 'text-size');
    if (textSize !== mapTextSize) {
      map.setLayoutProperty(SYMBOL_LAYER_ID, 'text-size', textSize)
    }
  }
}

const returnRrnFilter = (rrnArray) => {
  const rrnStringArray = rrnArray.map(x => x.toString());
  const returnFilter = ['in', 'rmrecnbr', ...rrnStringArray];
  return returnFilter;
}

// const returnTextMapExpression = (dataArr, textKey, rrnKey) => {
//   if (!textKey) return '';
//   const returnExpression = ["case",]
//   dataArr.forEach(x => {
//     returnExpression.push(...[["==", ["get", "rmrecnbr"], String(x[rrnKey])], String(x[textKey])]);
//   });
//   returnExpression.push("text");
//   return returnExpression;
// }

const returnTextMapExpression = (settingObj, conditionObj, data, rrnKey) => {

  if (settingObj.textField && settingObj.textField !== 'conditional') {
    const returnExpression = ['case',];
    const textKey = settingObj.textField;
    data.forEach(obj => {
      returnExpression.push(...[["==", ["get", "rmrecnbr"], String(obj[rrnKey])], String(obj[textKey])]);
    });
    returnExpression.push('');
    return returnExpression;
  }
  if (conditionObj.textField) {
    const defaultValue = conditionObj.textField.default || '';
    const returnExpression = ['case',];
    data.forEach(obj => {
      const lookupKey = conditionObj.textField.conditionKey;
      const value = conditionObj.textField.settingValue;
      let valid = false;
      if (
        conditionObj.textField.conditionType === 'category' &&
        obj[lookupKey] === conditionObj.textField.conditionValue
      ) {
        valid = true;
      }
      if (
        conditionObj.textField.conditionType === 'number' &&
        conditionObj.textField.conditionValue &&
        Array.isArray(conditionObj.textField.conditionValue) &&
        obj[lookupKey] >= Number(conditionObj.textField.conditionValue[0]) &&
        obj[lookupKey] <= Number(conditionObj.textField.conditionValue[1])
      ) {
        valid = true;
      }
      if (value && valid) {
        returnExpression.push(...[["==", ["get", "rmrecnbr"], String(obj[rrnKey])], value]);
      }
    });
    returnExpression.push(defaultValue);
    return returnExpression;
  }
  // default
  return '';
}

const returnExpression = (settingObj, conditionObj, data, rrnKey, key) => {
  const settingDefault = settingLookup[key].defaultValue;
  if (settingObj[key] && settingObj[key] !== 'conditional') {
    return settingObj[key];
  }

  if (!isEmpty(conditionObj[key])) {
    const returnExpression = ["case",];
    const { conditionKey, conditionType, conditionArray } = conditionObj[key];
    const defaultCondition = conditionArray.find(x => x.conditionValue === 'default') 
    const defaultValue = defaultCondition ? defaultCondition.settingValue : settingDefault;
    data.forEach(obj => {
      const rrn = obj[rrnKey];
      if (
        conditionType === 'category'
      ) {
        const condition = conditionArray.find(x => x.conditionValue === obj[conditionKey]);
        const value = condition && condition.settingValue;
        if (value) returnExpression.push(...[["==", ["get", "rmrecnbr"], String(rrn)], value]);
      }
      if (
        conditionType === 'number'
      ) {
        const number = Number(obj[conditionKey]);
        const condition = conditionArray.find(x => {
          const min = x.conditionValue && x.conditionValue[0];
          const max = x.conditionValue && x.conditionValue[1];
          return (
            min &&
            max &&
            number >= Number(min) &&
            number <= Number(max)
          )
        });
        const value = condition && condition.settingValue;
        if (value) returnExpression.push(...[["==", ["get", "rmrecnbr"], String(rrn)], value]);
      }
    });
    returnExpression.push(defaultValue);
    return returnExpression;
  }
  // default
  return settingDefault;
}
