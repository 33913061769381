import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
  drawer: {
    width: '100vw',
    minWidth: 335,
    // maxWidth: 600,
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      width: '80vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95vw',
    }
  }
}));


export default function LargeDrawer({ isVisible, onClose, children }) {
  const classes = useStyles();

  return (
    <Drawer
      open={isVisible}
      onClose={onClose}
      anchor="bottom"
      classes={{paperAnchorBottom: classes.drawer}}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <IconButton
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
    </Drawer>
  )
}
