import React, { useState } from 'react';
import LargeDrawer from "../Shared/LargeDrawer";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import EditIcon from '@material-ui/icons/Edit';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { makeStyles } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import { useDispatch, useSelector } from 'react-redux';
import { SettingColorSelector, SettingSlider } from '../Spreadsheet/SpreadsheetSettingSharedComponents';
import {
  List,
  IconButton,
  ListItem,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Switch,
  Tooltip,
  Box,
  Fab,
} from '@material-ui/core';
import {
  actionSharedDatasetSettingsDrawerToggled,
  actionSharedDatasetSettingCreated,
  actionSharedDatasetSettingUpdated,
  actionSharedDatasetSettingDeleted,
  actionSharedDatasetConditionCreated,
  actionSharedDatasetConditionUpdated,
  actionSharedDatasetConditionDeleted
} from './index';
import { settingLookup } from './sharedDatasetDefaultData';

const useStyles = makeStyles(theme => ({
  settingItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  settingOptionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  settingActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  conditionActionContainer: {

  }
}));


export default function SharedDatasetSettings() {
  const dispatch = useDispatch();
  const { conditionsDrawer: id } = useSelector(state => state.sharedDatasetReducer.ui);
  const { allIds: settingsAllIds, byId: settingsById } = useSelector(state => state.sharedDatasetReducer.settings)
  const [tempSetting, setTempSetting] = useState(null);

  const handleDrawerDismissal = () => {
    dispatch(actionSharedDatasetSettingsDrawerToggled())
  }

  const handleNewSetting = () => {
    const settingId = generateSettingId(id, settingsAllIds);
    setTempSetting(settingId)
  }

  const trashTemp = () => {
    setTempSetting(null);
  }

  // todo disable FAB is setting list exhausted
  return (
    <LargeDrawer
      isVisible={!!id}
      onClose={handleDrawerDismissal}
    >
      <div style={{ position: 'absolute', margin: '10px' }}>
        <Tooltip title="Add Setting">
          <Fab
            color='primary'
            size="small"
            onClick={handleNewSetting}
            disabled={!!tempSetting}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
      <List>
        {
          tempSetting &&
          <TempSettingWrapper
            id={id}
            settingId={tempSetting}
            trashTemp={trashTemp}
          />
        }
        {settingsAllIds.toReversed().map((settingId) => {
          if (settingsById[settingId].isBeingEdited) {
            return (
              <EditSettingWrapper
                id={id}
                settingId={settingId}
                value={settingsById[settingId].settingValue}
                type={settingsById[settingId].settingType}
              />
            );
          }
          return (
            <React.Fragment key={settingId} >
              <SettingListItem settingId={settingId} />
              <ConditionsList settingId={settingId} />
            </React.Fragment>
          );
        })}
      </List >
    </LargeDrawer >
  )
}

const TempSettingWrapper = ({ id, settingId, trashTemp }) => {
  const { byId, allIds } = useSelector(state => state.sharedDatasetReducer.settings)
  const { allIds: conditionsAllIds } = useSelector(state => state.sharedDatasetReducer.conditions);
  const [settingType, setSettingType] = useState('');
  const [settingValue, setSettingValue] = useState('');
  const [conditional, setConditional] = useState(false);
  const [conditonalCategory, setConditionalCategory] = useState('');
  const [conditionalHeader, setConditionalHeader] = useState('')
  const dispatch = useDispatch();
  const classes = useStyles();

  const usedSettings = allIds.map(id => byId[id].settingType);

  if (!id) return '';

  const handleTypeChange = (e) => {
    setSettingType(e.target.value)
    setSettingValue(settingLookup[e.target.value].defaultValue)
  }

  const handleValueChange = (val) => {
    setSettingValue(val);
  }

  const handleSave = () => {
    if (!id || !settingId || !settingType || !settingValue) {
      console.error('incomplete setting')
    }
    dispatch(actionSharedDatasetSettingCreated({
      sheetId: id,
      settingId: settingId,
      settingType: settingType,
      settingValue: settingValue
    }));
    trashTemp();
  }

  const handleTempTrash = () => {
    trashTemp();
  }

  const handleConditional = () => {
    setConditional(!conditional);
  }

  const handleConditionalCategoryChange = (e) => {
    setConditionalCategory(e.target.value);
  }

  const handleConditionalHeaderChange = (e) => {
    setConditionalHeader(e.target.value)
  }

  const handleConditionalSave = () => {
    const conditionId = generateConditionId(id, conditionsAllIds, settingId);
    dispatch(actionSharedDatasetSettingCreated({
      sheetId: id,
      settingId: settingId,
      settingType: settingType,
      settingValue: 'conditional'
    }));
    dispatch(actionSharedDatasetConditionCreated({
      sheetId: id,
      settingId: settingId,
      conditionId: conditionId,
      settingType: settingType,
      conditionType: conditonalCategory,
      conditionKey: conditionalHeader,
      isBeingEdited: true,
      // settingValue: ,
      // conditionValue: ,
    }));
    trashTemp();
  }

  return (
    <>
      <ListItem
        key={`temp-setting-${settingId}`}
        className={classes.settingItem}
      >
        <div className={classes.settingOptionContainer}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl style={{ minWidth: '200px', margin: "0 10px" }}>
              <InputLabel id="setting-label">Setting</InputLabel>
              <Select
                autoWidth
                labelId='setting-label'
                id='setting'
                value={settingType}
                onChange={handleTypeChange}
              >
                {Object.keys(settingLookup).filter(x => !usedSettings.includes(x)).map(key => {
                  return (
                    <MenuItem key={key} value={key} style={{ display: 'flex', alignItems: 'center' }}>
                      {settingLookup[key].icon}{settingLookup[key].text}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={conditional}
                onChange={handleConditional}
              />
            }
            label="Conditional"
          />
          <div>
            {!conditional &&
              renderSettingValueSelector(settingType, handleValueChange, settingValue, id)}
          </div>
          <div style={{ display: 'flex' }}>
            {
              conditional &&
              <>
                <FormControl style={{ minWidth: '200px', margin: "0 10px" }}>
                  <InputLabel id="condition-type-label">Condition Type</InputLabel>

                  <Select
                    autoWidth
                    id="condition-type-select"
                    labelId='condition-type-label'
                    value={conditonalCategory}
                    onChange={handleConditionalCategoryChange}
                  >
                    <MenuItem value="category">
                      Category
                    </MenuItem>
                    <MenuItem value="number">
                      Number
                    </MenuItem>
                    {/* <MenuItem value="date">
                      Date
                    </MenuItem> */}
                  </Select>
                </FormControl>
                <TextDropdown
                  value={conditionalHeader}
                  id={id}
                  handleValueChange={handleConditionalHeaderChange}
                  label="Conditional Column"
                />
              </>
            }
          </div>
        </div>
        <div className={classes.settingActionContainer}>
          {!conditional &&
            <Tooltip title="Save">
              <IconButton
                onClick={handleSave}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          }
          {conditional &&
            <Tooltip title="Add Condition">
              <IconButton
                onClick={handleConditionalSave}
              >
                <AddBoxIcon />
              </IconButton>
            </Tooltip>
          }
          <Tooltip title="Delete">
            <IconButton
              onClick={handleTempTrash}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </ListItem>
    </>
  )
}

const EditSettingWrapper = ({ settingId, type, value, id }) => {
  const { byId } = useSelector(state => state.sharedDatasetReducer.settings);
  const [editValue, setEditValue] = useState(value);
  const dispatch = useDispatch();

  const handleValueChange = (e) => {
    setEditValue(e);
  }

  const handleSave = () => {
    dispatch(actionSharedDatasetSettingUpdated({
      settingId: settingId,
      settingValue: editValue,
      isBeingEdited: false
    }));
  }

  const handleTrash = () => {
    dispatch(actionSharedDatasetSettingDeleted({
      settingId: settingId
    }));
  }

  const handleCancel = () => {
    dispatch(actionSharedDatasetSettingUpdated({
      settingId: settingId,
      isBeingEdited: false
    }));
  }

  return (
    <>
      <ListItem
        key={`edit-setting-${settingId}`}
        style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <div>
          {settingLookup[byId[settingId].settingType].icon}
        </div>
        <div>
          {renderSettingValueSelector(type, handleValueChange, editValue, id)}
        </div>
        <div>
          <Tooltip title="Save">
            <IconButton
              onClick={handleSave}
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton
              onClick={handleCancel}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={handleTrash}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </ListItem>
    </>
  )
}

const renderSettingValueSelector = (type, handleValueChange, value, sheetId, disabled = false) => {
  const handleColorPicker = (value) => {
    handleValueChange(value.hex)
  }

  const handleTextPicker = (e) => {
    handleValueChange(e.target.value)
  }

  if (value === 'conditional') {
    return (
      <div style={{ marginLeft: '5px'}}>
        Conditional
      </div>
    )
  }

  return (
    <>
      {(() => {
        switch (type) {
          case 'lineColor':
          case 'fillColor':
          case 'textColor':
            return (
              <div style={{ width: 100, margin: "0 10px" }}>

                <SettingColorSelector
                  fillColor={value}
                  defaultColor={value}
                  handlePickerClose={handleColorPicker}
                  active={!disabled}
                />
              </div>
            );
          case 'lineWidth':
          case 'textSize':
          case 'fillOpacity':
            return (
              <div style={{ width: 200 }}>
                <SettingSlider
                  handleNewValue={handleValueChange}
                  min={settingLookup[type].min}
                  max={settingLookup[type].max}
                  step={settingLookup[type].step}
                  value={value}
                  active={!disabled}
                />

              </div>
            );
          case 'textField':
            return (
              <TextDropdown
                value={value}
                id={sheetId}
                handleValueChange={handleTextPicker}
                label='Label Column'
                disabled={disabled}
              />
            );
          default:
            return (
              <div>{type}</div>
            )
        }
      })()}
    </>
  )
}

const generateSettingId = (sheetId, array) => {
  return generateId(sheetId, array, false);
}

const generateConditionId = (sheetId, array, settingId) => {
  return generateId(sheetId, array, settingId);
}

const generateId = (sheetId, array, settingId = false) => {
  const settingNum = settingId ? settingId.split('-')[0] + '-' : '';
  if (array.length === 0) {
    const id = `0-${settingNum}${sheetId}`;
    return id;
  } else {
    const lastItem = array[array.length - 1];
    const idNumOfLastItem = lastItem.split('-')[0];
    const nextId = Number(idNumOfLastItem) + 1;
    const id = `${nextId}-${settingNum}${sheetId}`;
    return id;
  }
}

const TextDropdown = ({ id, handleValueChange, value, label, disabled }) => {
  const { byId } = useSelector(state => state.sharedDatasetReducer.files);
  const headers = Object.keys(byId[id].data[0]);
  return (
    <div>
      <FormControl
        disabled={disabled}
        style={{ minWidth: '200px', margin: "0 10px" }}
      >
        <InputLabel id="setting-label">{label}</InputLabel>
        <Select
          autoWidth
          labelId='setting-label'
          id='setting'
          value={value}
          onChange={handleValueChange}
        >
          {
            headers.map(x => (

              <MenuItem
                key={x}
                value={x}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {x}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </div>
  )
}

// todo rename category header
const TempConditional = ({ conditionId }) => {
  const { allIds, byId } = useSelector(state => state.sharedDatasetReducer.conditions)
  const { settingType, settingValue, conditionKey, conditionValue, sheetId, settingId, conditionType } = byId[conditionId];
  const [value, setValue] = useState(settingValue || settingLookup[settingType].defaultValue);
  const [headerValue, setHeaderValue] = useState(conditionValue || '');
  const dispatch = useDispatch();

  const handleValueChange = (x) => {
    setValue(x)
  }

  const handleHeaderValueChange = (x) => {
    setHeaderValue(x)
  }

  const handleConditionSave = () => {
    dispatch(actionSharedDatasetConditionUpdated({
      conditionId: conditionId,
      settingValue: value,
      conditionValue: headerValue,
      isBeingEdited: false
    }))
  }

  const handleAdd = () => {
    dispatch(actionSharedDatasetConditionUpdated({
      conditionId: conditionId,
      settingValue: value,
      conditionValue: headerValue,
      isBeingEdited: false
    }));
    const newCondId = generateConditionId(sheetId, allIds, settingId);
    dispatch(actionSharedDatasetConditionCreated({
      conditionId: newCondId,
      isBeingEdited: true,
      sheetId: sheetId,
      settingId: settingId,
      settingType,
      conditionKey: conditionKey,
      conditionType: conditionType
    }))
  }

  return (
    <ListItem
      style={{ paddingLeft: 25 }}

    >
      <div style={{ display: 'flex', width: '100%' }}>
        {/* keyvalue selector Category / Number / Date */}
        <div style={{ flexGrow: 2, display: 'flex' }}>

          {/* <ConditionalCategorySelector
            sheetId={sheetId}
            conditionalHeader={conditionKey}
            value={headerValue}
            handleValueChange={handleHeaderValueChange}
          /> */}
          <ConditionalKeySelector
            sheetId={sheetId}
            conditionalHeader={conditionKey}
            value={headerValue}
            handleValueChange={handleHeaderValueChange}
            conditionType={conditionType}
          />
          {renderSettingValueSelector(settingType, handleValueChange, value, sheetId)}
        </div>
        <div>

          <IconButton
            onClick={handleAdd}
          >
            <AddBoxIcon />
          </IconButton>
          <IconButton
            onClick={handleConditionSave}
          >
            <SaveIcon />
          </IconButton>
        </div>
      </div>
    </ListItem>
  );
}

const ConditionalKeySelector = ({ conditionType, sheetId, conditionalHeader, value, handleValueChange }) => {
  if (conditionType === 'category') {
    return (
      <ConditionalCategorySelector
        sheetId={sheetId}
        conditionalHeader={conditionalHeader}
        value={value}
        handleValueChange={handleValueChange}
      />
    )
  }
  if (conditionType === 'number') {
    return (
      <ConditionalNumberSelector
        sheetId={sheetId}
        conditionalHeader={conditionalHeader}
        value={value}
        handleValueChange={handleValueChange}
      />
    )
  }
  if (conditionType === 'date') {
    return (
      "date picker"
    )
  }

}

const ConditionalCategorySelector = ({ sheetId, conditionalHeader, value, handleValueChange }) => {
  const { byId } = useSelector(state => state.sharedDatasetReducer.files);
  const { data } = byId[sheetId];
  const values = [...new Set(data.map(x => x[conditionalHeader])), 'default'];

  const handleChange = (e) => {
    handleValueChange(e.target.value)
  }

  return (
    <FormControl
      style={{ minWidth: '200px' }}
    >
      <InputLabel>{conditionalHeader}</InputLabel>
      <Select
        autoWidth
        value={value}
        onChange={handleChange}
      >
        {values.map((v, i) => (
          <MenuItem
            value={v}
            key={`${v}-${i}`}
          >
            {v}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const ConditionalNumberSelector = ({ sheetId, conditionalHeader, value, handleValueChange }) => {
  const { byId } = useSelector(state => state.sharedDatasetReducer.files);
  const { data } = byId[sheetId];
  const values = [...new Set(data.map(x => x[conditionalHeader]))];
  // todo condition id???
  const conditionId = 'xx';
  const mathMin = Math.min(...values);
  const mathMax = Math.max(...values);
  const [min, setMin] = useState(value[0] || '');
  const [max, setMax] = useState(value[1] || '');

  const defaultMin = values.length > 0 ? mathMin.toString() : '';
  const defaultMax = values.length > 0 ? mathMax.toString() : '';

  const handleMax = (e) => {
    setMax(e.target.value);
    const valueMin = min || mathMin;
    const valueMax = e.target.value || mathMax;
    handleValueChange([valueMin, valueMax])
  }

  const handleMin = (e) => {
    setMin(e.target.value);
    const valueMin = e.target.value || mathMin;
    const valueMax = max || mathMax;
    handleValueChange([valueMin, valueMax]);
  }

  return (
    <>
      <TextField
        inputProps={{
          min: defaultMin,
          max: defaultMax
        }}
        // disabled={!activeVal}
        size="small"
        fullWidth
        type="number"
        id={`cond-${conditionId}-min`}
        variant="standard"
        placeholder={defaultMin}
        value={min}
        onChange={handleMin} />
      <TextField
        inputProps={{
          min: defaultMin,
          max: defaultMax
        }}
        size="small"
        fullWidth
        type="number"
        id={`cond-${conditionId}-max`}
        variant="standard"
        placeholder={defaultMax}
        value={max}
        onChange={handleMax} />

    </>
  );
}

const SettingListItem = ({ settingId }) => {
  const { byId } = useSelector(state => state.sharedDatasetReducer.settings);
  const dispatch = useDispatch();
  const {
    settingType,
    settingValue,
    sheetId,
  } = byId[settingId];

  const handleTrashSetting = (settingId) => {
    dispatch(actionSharedDatasetSettingDeleted({
      settingId
    }));
  }
  const handleEditSetting = (settingId) => {
    dispatch(actionSharedDatasetSettingUpdated({
      settingId,
      isBeingEdited: true,
    }))
  }

  return (
    <ListItem key={`setting-item-${settingId}`} dense style={{
      display: 'flex'
    }}>
      <div style={{ flexGrow: 2, display: 'flex', alignItems: 'center' }}>
        {settingLookup[byId[settingId].settingType].icon}
        {settingLookup[byId[settingId].settingType].text}
        {renderSettingValueSelector(settingType, null, settingValue, sheetId, true)}
      </div>
      <div>
        {
          settingValue !== 'conditional' &&
          <IconButton
            onClick={
              () => handleEditSetting(settingId)
            }
          >
            <EditIcon />
          </IconButton>
        }
        <IconButton
          onClick={
            () => handleTrashSetting(settingId)
          }
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </ListItem>
  );
}

const ConditionsList = ({ settingId }) => {
  const { allIds, byId } = useSelector(state => state.sharedDatasetReducer.conditions)

  return (
    <div
      key={`conditions-list-${settingId}`}
      style={{
        backgroundColor: '#e0e0e0'
      }}
    >
      {
        allIds
          .filter(conditionId => byId[conditionId].settingId === settingId)
          .map(conditionId => {
            if (byId[conditionId].isBeingEdited) {
              return (

                <TempConditional
                  key={conditionId}
                  conditionId={conditionId}
                />
              )
            } else {
              return (
                <ConditionListItem
                  key={conditionId}
                  conditionId={conditionId}
                />
              )

            }


          })

      }
    </div>
  )
}

const ConditionListItem = ({ conditionId }) => {
  const dispatch = useDispatch();
  const { byId } = useSelector(state => state.sharedDatasetReducer.conditions);
  if (!conditionId) return '';

  const {
    settingType,
    settingValue,
    conditionKey,
    conditionValue,
    sheetId
  } = byId[conditionId]

  const handleEditCondition = (conditionId) => {
    dispatch(actionSharedDatasetConditionUpdated({
      conditionId,
      isBeingEdited: true,
    }));
  }

  const handleTrashCondition = (conditionId) => {
    dispatch(actionSharedDatasetConditionDeleted({
      conditionId
    }));
  }


  return (
    <ListItem
      style={{ paddingLeft: 25 }}
    >
      <div style={{ display: 'flex', width: '100%' }}>

        <div style={{ flexGrow: 2, display: 'flex', alignItems: 'center' }}>
          <div style={{ minWidth: '200px' }}>
            {conditionValue}
          </div>
          <div>
            {renderSettingValueSelector(settingType, null, settingValue, sheetId, true)}
          </div>
        </div>
        <IconButton
          onClick={
            () => handleEditCondition(conditionId)
          }
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={
            () => handleTrashCondition(conditionId)
          }
        >
          <DeleteIcon />
        </IconButton>
      </div>

    </ListItem>

  );
}
