import { queryRoom, rrnToBbox } from '../Search/searchApi';
import { isObject } from "lodash";

const getBldingBBox = async (rrn, token) => {
  let bbox = await rrnToBbox(rrn, token);
  return bbox;
};

export const getBuildingData = async (rooms, idToken) => {
  const bldrecnbrArray = [...new Set(rooms.filter(room => room && room.bldrecnbr).map(room => room && room.bldrecnbr))];
  const buildObj = {};
  bldrecnbrArray.forEach(bldrecnbr => {
    const floorArray = [...new Set(rooms.filter(room => room && room.bldrecnbr === bldrecnbr).map(room => room.floor))];
    buildObj[bldrecnbr] = floorArray;
  });

  const buildingData = [];
  for (const building in buildObj) {
    for (const floor of buildObj[building]) {
      const buildingRoomsArray = rooms.filter(room => room && room.bldrecnbr === building && room.floor === floor);
      const totalSqFeet = buildingRoomsArray
        .map(room => room.rmsqrft)
        .reduce((a, b) => a + b);
      const { bld_descrshort } = rooms.find(room => room && String(room.bldrecnbr) === String(building));
      const roomsRrn = buildingRoomsArray.map(room => room && room.rmrecnbr)
      const bbox = await getBldingBBox(roomsRrn, idToken);
      buildingData.push({
        data: {
          building: bld_descrshort,
          floor,
          totalSqFeet
        },
        bbox
      });
    }
  }
  return buildingData;
};

export const getRoomData = async (rrns, idToken) => {
  const getRooms = async (token) => {
    return Promise.all(rrns.map(rrn => {
      return (async (rrn, token) => {
        const data = await queryRoom(rrn, token)
        return data;
      })(rrn, token);
    }));
  }
  const rooms = await getRooms(idToken);
  return rooms;
}

export const getDataHeaders = (data) => {
  return data && 
    data.length > 0 && 
    data[0] && 
    isObject(data[0]) && 
    Object.keys(data[0]);
}