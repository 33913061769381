import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { actionAppToSmallScreen } from './AppRedux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Auth from '../Auth';
import Map from '../Map';
import AppBar from '../AppBar';
import AccordionContainer from './AccordionContainer';
import Widgets from './Widgets';
import Layer from '../Layer';
import { CustomLayerRestroom } from '../CustomLayer';
import Info from '../Info';
import SearchResult from '../Data';
import Print from '../Print';
import Draw from '../Draw';
import Legend from '../Legend';
import Search from '../Search';
import Zoom from '../Zoom';
import Rotate from '../Rotate';
import MapviewControl from '../MapviewControl';
import Photo from '../Photo';
import Alert from '../Alert';
import Spreadsheet, { Upload, DragAndDrop }from '../Spreadsheet';
import SharedDataset from '../SharedDataset';
import ReactGA from 'react-ga4';
import SharedDatasetFileList from '../SharedDataset/SharedDatasetFileList';
import SharedDatasetSettings from '../SharedDataset/SharedDatasetSettings';

const ACCORDIONS = {
  resultDataVisibile: <SearchResult key='data' />,
  infoVisible: <Info key='info' />,
  layersVisible: <Layer key='layer' />,
  customLayerRestroomVisible: <CustomLayerRestroom key='customlayerrestroom' />,
  printVisible: <Print key='print' />,
  drawVisible: <Draw key='draw' />,
  legendVisible: <Legend key='legend' />,
  spreadsheetVisible: <Spreadsheet key='spreadsheet' />,
  sharedDatasetVisible: <SharedDataset key='shareddataset' />,
};

export default function App() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  useEffect(() => {
    dispatch(actionAppToSmallScreen(xsDown));
  }, [dispatch, xsDown]);

  useEffect(() => {
    try {
      ReactGA.initialize(process.env.REACT_APP_GA4_TAG);
    } catch (e) {
      console.error(e);
    }
  });

  const accordions = useSelector(state => state.appReducer.accordions);
  const accordionElts = accordions.map(accordion => ACCORDIONS[accordion]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Auth />
      <Alert />
      <div>
        <DragAndDrop>
          <Map />
          <AppBar />
          <AccordionContainer>
            {accordionElts}
          </AccordionContainer>
          <Search />
          <Upload />
          <SharedDatasetFileList />
          <SharedDatasetSettings />
          <Widgets>
            <MapviewControl />
            <Rotate />
            <Zoom />
          </Widgets>
          <Photo />
        </DragAndDrop>
      </div>
    </React.Fragment>
  );
}