import { 
  actionMapViewChanged,
  actionMapPopupRequested,
  actionMapCursorUpdated,
  actionMapUpdateZoomFeatures,
  actionMapUpdateActiveFeature,
  actionMapSourceDataLoaded,
} from './MapRedux';
import {
  mapViewToFloors,
  getUniqueFeatures,
  getMapView,
  createPopup,
  getActiveFeature,
  featuresToFloors,
  checkClick
} from './maputils';
import { actionAppBarFloorsInViewUpdated, actionAppBarFloorsInActiveBuildingUpdated } from '../AppBar';
import { actionInfoDataAddedBackground, actionInfoDataAddedForeground } from '../Info';
import { actionAuthSessionExpired } from '../Auth';
import { actionSheetRoomClicked, actionSheetPointClicked } from '../Spreadsheet';
import { actionDrawUpdateClickCounter, actionDrawRoomSelect } from '../Draw';
import debounced from 'lodash/debounce';
import { actionUpdateMapviewHistory } from '../App';
import { MAP_INTERACTION, SPREADSHEET, trackEvent } from '../../tracking';
import { actionPhotoGetTargetRoom } from '../Photo';
import { actionCustomLayerSelectedRoomUpdated } from '../CustomLayer';
import { handleSharedDataClick } from '../SharedDataset/sharedDatasetEventHandlers';

export const mapClickHandler = (map, dispatch) => (event) => {
  const features = map.queryRenderedFeatures(event.point);

  if (features && Array.isArray(features) && features.length > 0 && features[0].layer.id.indexOf('sheet') === 0) {
    handleCustomClick(features, dispatch);
  }

  handleSharedDataClick(features, dispatch);

  dispatch(actionDrawUpdateClickCounter());
  dispatch(actionMapUpdateActiveFeature(getActiveFeature(event.features)));
}

export const mapTouchHandler = (map, dispatch) => (event) => {

  // touchend "click" event for custom, building, and room touches
  const touchEndFunction = (e) => {
    const features = map.queryRenderedFeatures(e.point);
    const buildingData = getUniqueFeatures(features, 'bldrecnbr');
    const roomData = getUniqueFeatures(features, 'rmrecnbr');
    const { customClick, roomClick, buildingClick } = checkClick(features);

    if (customClick) {
      handleCustomClick(features, dispatch);
    } else if (roomClick) {
      dispatch(actionInfoDataAddedForeground({ data: roomData }));
      trackEvent({
        category: MAP_INTERACTION,
        action: 'Touch Room',
        label: roomData[0].rmrecnbr
      });
    } else if (buildingClick) {
      dispatch(actionInfoDataAddedForeground({ data: buildingData }));
      trackEvent({
        category: MAP_INTERACTION,
        action: 'Touch Building',
        label: buildingData[0].bldrecnbr
      });
    }
    dispatch(actionDrawUpdateClickCounter())
    dispatch(actionMapUpdateActiveFeature(getActiveFeature(features)));
  }

  // add touchend "click" listener on touchstart
  map.once('touchend', touchEndFunction)

  // remove touchend "click" listener if touchmove occurs
  map.once('touchmove', () => {
    map.off('touchend', touchEndFunction)
  });
}

const handleCustomClick = (features, dispatch) => {
  if (features.length === 0) return;

  const id = parseInt((features[0].layer.id).split('-')[2]);
  const type = (features[0].layer.id).split('-')[1];

  if (type !== 'point') {
    const { rmrecnbr: selectedRmrecnbr, rmnbr: selectedRmnbr } = features[0].properties;
    dispatch(actionSheetRoomClicked({
      id,
      selectedRmrecnbr,
      selectedRmnbr
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Click Custom Room',
      label: selectedRmrecnbr
    });
  }
  if (type === 'point') {
    const feat = features[0]
    const { sheetRow: selectedPoint } = feat.properties;
    dispatch(actionSheetPointClicked({
      id,
      selectedPoint
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Click Custom Point',
      label: selectedPoint
    })
  }
}

export const mapMoveendHandler = (map, dispatch) => (event) => {
  const mapView = getMapView(map);
  dispatch(actionMapViewChanged(mapView));
  const floors = mapViewToFloors(map);
  dispatch(actionAppBarFloorsInViewUpdated(floors));
  dispatch(actionUpdateMapviewHistory(mapView));
} 

export const mapClickBuildingHandler = (map, dispatch) => (event) => {
  const data = getUniqueFeatures(event.features, 'bldrecnbr');
  const features = map.queryRenderedFeatures(event.point);
  const { customClick, roomClick } = checkClick(features);

  if (customClick && !roomClick) {
    dispatch(actionInfoDataAddedBackground({ data }));
  } else {
    // skip building info change if room layer clicked
    if (!roomClick) {
      dispatch(actionInfoDataAddedForeground({ data }));
    }
    dispatch(actionMapUpdateActiveFeature(getActiveFeature(event.features)));
    dispatch(actionAppBarFloorsInActiveBuildingUpdated(featuresToFloors(event.features)));
  }
}

export const mapClickRoomHandler = (map, dispatch) => (event) => {
  const features = map.queryRenderedFeatures(event.point);
  const { customClick } = checkClick(features);
  const data = getUniqueFeatures(event.features, 'rmrecnbr');

  if (customClick) {
    dispatch(actionInfoDataAddedBackground({ data }));
  } else {
    dispatch(actionInfoDataAddedForeground({ data }));
    dispatch(actionPhotoGetTargetRoom(data));
    dispatch(actionDrawRoomSelect(event.features));
    dispatch(actionMapUpdateActiveFeature(getActiveFeature(event.features)));
    dispatch(actionCustomLayerSelectedRoomUpdated(data));
    trackEvent({
      category: MAP_INTERACTION,
      action: 'Click Room',
      label: getActiveFeature(event.features).rmrecnbr
    });
  }
}

export const mapMouseoverBuildingHandler = (map, dispatch) => debounced((event) => {
  const { coordinate, content } = createPopup(event, 'building');
  dispatch(actionMapPopupRequested({ coordinate, content }));
  dispatch(actionMapCursorUpdated({featureHover: true}));
}, 10);

export const mapMouseoverRoomHandler = (map, dispatch) => debounced((event) => {
  const { coordinate, content } = createPopup(event, 'room');
  dispatch(actionMapPopupRequested({ coordinate, content }));
  dispatch(actionMapCursorUpdated({featureHover: true}));
}, 10);

export const mapMouseoutBuildingHandler = (map, dispatch) => debounced((event) => {
  dispatch(actionMapCursorUpdated({featureHover: false}));
}, 10);

export const mapMouseoutRoomHandler = (map, dispatch) => debounced((event) => {
  dispatch(actionMapCursorUpdated({featureHover: false}));
}, 10);

export const mapDbclickBuildingHandler = (map, dispatch) => (event) => {
  dispatch(actionMapUpdateZoomFeatures({ data: event.features }));
}

export const mapDbclickRoomHandler = (map, dispatch) => (event) => {
  dispatch(actionMapUpdateZoomFeatures({ data: event.features }));
}

export const mapErrorHandler = (map, dispatch) => (event) => {
  // handle expired session (after 1 hour)
  if (event.type === 'error' && event.error.message === 'Failed to fetch') {
    dispatch(actionAuthSessionExpired());
  }
}

export const mapClickCustomLayerHandler = (map, dispatch, id) => (event) => {
  const { rmrecnbr: selectedRmrecnbr, rmnbr: selectedRmnbr } = event.features[0].properties;
  dispatch(actionSheetRoomClicked({
    id,
    selectedRmrecnbr,
    selectedRmnbr
  }));
  trackEvent({
    category: SPREADSHEET,
    action: 'Click Custom Room',
    label: selectedRmrecnbr
  });
};

export const mapDataSourceLoadedHandler = (dispatch) => (event) => {
  if (event.isSourceLoaded && event.sourceId && event.sourceId === 'campus-building') {
    dispatch(actionMapSourceDataLoaded(true));
  }
}
