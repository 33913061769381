import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from "../Shared/Accordion";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles'
import {
  LinearProgress,
  Typography,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItem,
  Tooltip,
  Collapse
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import {
  actionSharedDatasetFilesLoaded,
  actionSharedDatasetExpandToggled,
  actionSharedDatasetFileListDrawerToggled,
  actionSharedDatasetSettingsDrawerToggled,
  actionSharedDatasetFileUpdated
} from './index';
import { actionAppToggleComponent } from '../App';
import { fetchSharedData, openGoogleSheet } from './gDriveApi';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BuildingDisplay from './SharedDatasetBuilding';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function SharedDataset() {
  const component = 'sharedDatasetVisible';
  const dispatch = useDispatch();
  const idToken = useSelector(state => state.authReducer.idToken);
  const visible = useSelector(state => state.appReducer[component]);
  const { componentExpanded: expand } = useSelector(state => state.sharedDatasetReducer.ui);
  const { allIds, byId } = useSelector(state => state.sharedDatasetReducer.files);
  const [loading, setLoading] = useState(false);
  const syncedIds = allIds.filter(id => byId[id].displayStatus === "displayed");
  const [showList, setShowList] = useState(syncedIds.length > 0);

  const visibleHandler = () => { dispatch(actionAppToggleComponent({ component })) }
  const expandHandler = () => { dispatch(actionSharedDatasetExpandToggled()) }
  const openFileList = () => { dispatch(actionSharedDatasetFileListDrawerToggled()) }

  const handleSharedData = async () => {
    setLoading(true);
    try {
      const files = await fetchSharedData(idToken);
      setShowList(true);
      dispatch(actionSharedDatasetFilesLoaded(files)); // Assuming the API returns an object with a files array
      openFileList();
    } catch (e) {
      dispatch(actionSharedDatasetFilesLoaded([])); // Clear previous data on error
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Accordion
      title='Shared Dataset'
      visible={visible}
      visibleHandler={visibleHandler}
      expand={expand}
      expandHandler={expandHandler}
    >
      <>
        <div style={{ height: 4 }}>
          {loading && <LinearProgress />}
        </div>
        {syncedIds.map(id => (
          <SyncedItem
            key={id}
            id={id} />
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <Tooltip title="Sync with Google Drive">
            <IconButton
              onClick={handleSharedData}
              disabled={loading}
              style={{ width: 30, height: 30 }}
            >
              <FontAwesomeIcon icon={faGoogleDrive} size='sm' />
            </IconButton>
          </Tooltip>
          <div>
            <Tooltip title="File List">
              <IconButton
                disabled={loading || !showList}
                onClick={openFileList}
                size="small"
              >
                <ListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </>
    </Accordion>
  );
};

const SyncedItem = ({ id }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expand, setExpand] = useState(true);
  const { byId } = useSelector(state => state.sharedDatasetReducer.files);
  const dispatch = useDispatch();
  const classes = useStyles();

  const { visibility, buildingData = [], syncStatus } = byId[id];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleExpand = () => {
    setExpand(!expand);
  }

  const handleMenuOpen = () => {
    setAnchorEl(null);
    dispatch(actionSharedDatasetSettingsDrawerToggled({
      id
    }));
  }

  const handleOpenSheet = () => {
    openGoogleSheet(id);
    setAnchorEl(null);
  }

  const handleVisibilityToggle = () => {
    dispatch(actionSharedDatasetFileUpdated({
      id,
      visibility: !visibility
    }));
  }

  const handleRemoveLayer = () => {
    dispatch(actionSharedDatasetFileUpdated({
      id,
      displayStatus: 'hidden'
    }));
  }

  return (
    <>
      <ListItem
        divider
        style={{ paddingRight: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
        key={id}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', width: '100%' }}
        >
          <Typography noWrap style={{ flexGrow: 1 }}>
            {byId[id].name}
          </Typography>
          <Tooltip title="Toggle Visibility">
            <IconButton
              size="small"
              onClick={handleVisibilityToggle}
            >
              {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Floors">
            <IconButton
              size="small"
              onClick={handleExpand}
              className={clsx(classes.expand, {
                [classes.expandOpen]: expand && buildingData.length > 0,
              })}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="More">
            <IconButton
              size="small"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              disabled={syncStatus !== "synced"}
              onClick={handleMenuOpen}
            >
              <ListItemIcon>
                <FormatColorFillIcon />
              </ListItemIcon>
              <Typography variant="overline">Format</Typography>
            </MenuItem>
            <MenuItem onClick={handleOpenSheet} >
              <ListItemIcon>
                <OpenInNewIcon />
              </ListItemIcon>
              <Typography variant="overline">Open Sheet</Typography>
            </MenuItem>
            <MenuItem onClick={handleRemoveLayer} >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <Typography variant="overline">Remove Layer</Typography>
            </MenuItem>
          </Menu>
        </div>
        <Collapse
          in={expand && buildingData.length > 0}
          style={{ width: '100%', marginRight: '25px' }}
        >
          <BuildingDisplay
            canZoom={true}
            buildingData={buildingData}
          />
        </Collapse>
      </ListItem>
    </>
  );
}