import { createAction, handleActions } from 'redux-actions';

const initialState = {
  ui: {
    componentExpanded: false,
    fileListDrawerExpanded: false,
    conditionsDrawer: false,
  },
  files: { byId: {}, allIds: [], },
  selected: {},
  settings: { byId: {}, allIds: [], },
  conditions: { byId: {}, allIds: [], }
}

// ui
export const actionSharedDatasetExpandToggled = createAction('SHARED_DATASET_EXPAND_TOGGLED');
export const actionSharedDatasetFileListDrawerToggled = createAction('SHARED_DATASET_FILELIST_DRAWER_TOGGLED');
export const actionSharedDatasetSettingsDrawerToggled = createAction('SHARED_DATASET_SETTINGS_DRAWER_TOGGLED');

// selected
export const actionSharedDatasetSelectLayer = createAction('SHARED_DATASET_SELECT_LAYER');

// files
export const actionSharedDatasetFilesLoaded = createAction('SHARED_DATASET_FILES_LOADED');
export const actionSharedDatasetFileUpdated = createAction('SHARED_DATASET_FILE_UPDATED');

// settings
export const actionSharedDatasetSettingCreated = createAction('SHARED_DATASET_SETTING_CREATED');
export const actionSharedDatasetSettingUpdated = createAction('SHARED_DATASET_SETTING_UPDATED');
export const actionSharedDatasetSettingDeleted = createAction('SHARED_DATASET_SETTING_DELETED');

// conditions
export const actionSharedDatasetConditionCreated = createAction('SHARED_DATASET_CONDITION_CREATED');
export const actionSharedDatasetConditionUpdated = createAction('SHARED_DATASET_CONDITION_UPDATED');
export const actionSharedDatasetConditionDeleted = createAction('SHARED_DATASET_CONDITION_DELETED');

export const sharedDatasetReducer = handleActions({
  [actionSharedDatasetExpandToggled]: (state, action) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        componentExpanded: !state.ui.componentExpanded
      }
    };
  },
  [actionSharedDatasetFilesLoaded]: (state, action) => {
    const allIds = [];
    const byId = {};
    action.payload.forEach(file => {
      allIds.push(file.id);
      byId[file.id] = {
        ...file
      }
    })
    return {
      ...state,
      files: {
        allIds,
        byId,
      }
    };
  },
  [actionSharedDatasetFileUpdated]: (state, action) => {
    const { id } = action.payload;
    return {
      ...state,
      files: {
        ...state.files,
        byId: {
          ...state.files.byId,
          [id]: {
            ...state.files.byId[id],
            ...action.payload
          }
        },
      },
    }
  },

  [actionSharedDatasetSelectLayer]: (state, action) => {
    const { rrn, driveId, brn } = action.payload;
    if ((rrn || brn) && driveId) {
      return {
        ...state,
        selected: {
          id: driveId,
          rmrecnbr: rrn,
          bldrecnbr: brn
        }
      }
    } else {
      return {
        ...state,
        selected: {}
      }
    }
  },
  [actionSharedDatasetFileListDrawerToggled]: (state, action) => {
    const { fileListDrawerExpanded } = state.ui;
    return {
      ...state,
      ui: {
        ...state.ui,
        fileListDrawerExpanded: !fileListDrawerExpanded
      }
    }
  },
  [actionSharedDatasetSettingsDrawerToggled]: (state, action) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        conditionsDrawer: action.payload ? action.payload.id : false,
      }
    }
  },
  [actionSharedDatasetSettingUpdated]: (state, action) => {
    const {
      settingId,
    } = action.payload;
    return {
      ...state,
      settings: {
        ...state.settings,
        byId: {
          ...state.settings.byId,
          [settingId]: {
            ...state.settings.byId[settingId],
            ...action.payload,
          }
        },
      }
    }
  },
  [actionSharedDatasetSettingCreated]: (state, action) => {
    const { settingId } = action.payload;
    return {
      ...state,
      settings: {
        byId: {
          ...state.settings.byId,
          [settingId]: {
            ...action.payload
          }
        },
        allIds: [...state.settings.allIds, settingId]
      }
    }
  },
  [actionSharedDatasetSettingDeleted]: (state, action) => {
    const { settingId } = action.payload;
    const newById = { ...state.settings.byId };
    delete newById[settingId];
    const newAllIds = [...state.settings.allIds].filter(x => x !== settingId);
    const newCondById = { ...state.conditions.byId };
    const newCondAllIds = [];
    state.conditions.allIds.forEach(condId => {
      if (state.conditions.byId[condId].settingId === settingId) {
        delete newCondById[condId];
      } else {
        newCondAllIds.push(condId);
      }
    });
    return {
      ...state,
      settings: {
        byId: newById,
        allIds: newAllIds
      },
      conditions: {
        byId: newCondById,
        allIds: newCondAllIds
      }
    }
  },
  [actionSharedDatasetConditionCreated]: (state, action) => {
    const { conditionId } = action.payload;
    return {
      ...state,
      conditions: {
        byId: {
          ...state.conditions.byId,
          [conditionId]: {
            conditionId,
            ...action.payload
          }
        },
        allIds: [...state.conditions.allIds, conditionId]
      }
    }
  },
  [actionSharedDatasetConditionUpdated]: (state, action) => {
    const { conditionId } = action.payload;
    return {
      ...state,
      conditions: {
        ...state.conditions,
        byId: {
          ...state.conditions.byId,
          [conditionId]: {
            ...state.conditions.byId[conditionId],
            ...action.payload
          }
        }
      }
    }
  },
  [actionSharedDatasetConditionDeleted]: (state, action) => {
    const { conditionId } = action.payload;
    const newById = { ...state.conditions.byId };
    delete newById[conditionId];
    const newAllIds = [...state.conditions.allIds].filter(x => x !== conditionId);
    return {
      ...state,
      conditions: {
        byId: newById,
        allIds: newAllIds
      }
    }
  }
}, initialState);