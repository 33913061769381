import React from 'react';
import LargeDrawer from "../Shared/LargeDrawer";
import { useDispatch, useSelector } from 'react-redux';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip
} from '@material-ui/core';
import {
  actionSharedDatasetFileUpdated,
  actionSharedDatasetFileListDrawerToggled
} from './index';
import { fetchFileContent, openGoogleSheet } from './gDriveApi';
import { getRoomData, getBuildingData, getDataHeaders } from './sharedDatasetUtils';

export default function SharedDatasetFileList() {
  const dispatch = useDispatch()
  const isExpanded = useSelector(state => state.sharedDatasetReducer.ui.fileListDrawerExpanded);
  const { files } = useSelector(state => state.sharedDatasetReducer)

  const handleDrawerDismissal = () => {
    dispatch(actionSharedDatasetFileListDrawerToggled())
  }

  return (
    <LargeDrawer
      isVisible={isExpanded}
      onClose={handleDrawerDismissal}
    >
      <List>
        {files.allIds.map(fileId => (
          <FileListItem
            fileId={fileId}
            key={fileId}
          />
        ))}
      </List>
    </LargeDrawer>
  )
}

const FileListItem = ({ fileId }) => {
  const { byId } = useSelector(state => state.sharedDatasetReducer.files);
  const idToken = useSelector(state => state.authReducer.idToken);
  const dispatch = useDispatch();
  const { syncStatus, displayStatus } = byId[fileId] && byId[fileId];

  // const isSynced = byId[fileId] &&
  //   byId[fileId].syncStatus &&
  //   byId[fileId].syncStatus === "synced";

  // const isLoading = byId[fileId] &&
  //   byId[fileId].syncStatus &&
  //   byId[fileId].syncStatus === "loading";

  const isError = byId[fileId] &&
    byId[fileId].syncStatus &&
    byId[fileId].syncStatus === "error";

  const handleCheck = () => {
    if (!syncStatus || isError) {
      // sync
      syncFileContent(fileId);
    } else {
      // toggle
      dispatch(actionSharedDatasetFileUpdated({
        id: fileId,
        displayStatus: displayStatus === 'displayed' ? 'hidden' : 'displayed'
      }))
    }
  }

  const syncFileContent = async (fileId) => {
    dispatch(actionSharedDatasetFileUpdated({
      id: fileId,
      data: {},
      syncStatus: 'loading',
      displayStatus: 'displayed'
    }));

    try {
      const content = await fetchFileContent(fileId, idToken);
      dispatch(actionSharedDatasetFileUpdated({
        id: fileId,
        data: content,
        syncStatus: 'synced',
        visibility: true
      }));
      const headers = getDataHeaders(content);
      const rrnKey = headers.find(x => ['rrn', 'rmrecnbr'].includes(x.trim().toLowerCase()));
      const rrns = content.map(x => x[rrnKey]);

      const rooms = await getRoomData(rrns, idToken);
      const buildingData = await getBuildingData(rooms, idToken);

      dispatch(actionSharedDatasetFileUpdated({
        id: fileId,
        buildingData
      }));

    } catch (e) {
      console.error('Failed to fetch file content: ' + e.message);
      dispatch(actionSharedDatasetFileUpdated({
        id: fileId,
        data: {},
        syncStatus: 'error',
      }));
    }
  };

  return (
    <ListItem
      divider
      key={fileId}
      dense
    >
      <ListItemText
        primary={byId[fileId].name}
        primaryTypographyProps={{
          noWrap: true
        }}
        style={{
          paddingInlineEnd: '20px'
        }}
      />
      <Tooltip title="Sync Data">
        <Checkbox
          checked={displayStatus === "displayed"}
          onChange={handleCheck}
        />
      </Tooltip>
      <Tooltip title="Open in GDrive">
        <IconButton
          size="small"
          onClick={() => { openGoogleSheet(fileId) }}
        >
          <OpenInNewIcon />
        </IconButton>
      </Tooltip>
    </ListItem>
  );
};
