import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { actionMapZoomFeaturesUpdated } from '../Map';
import { actionAppBarFloorUpdated } from '../AppBar';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 275,
    marginBottom: 8,
    '&:hover': {
      backgroundColor: 'rgba(217, 217, 217, 0.2)',
    }
  },
  cardError: {
    border: "1px solid #d32f2f",
  },
  buttonError: {
    color: '#d32f2f',
  },
  cardContent: {
    padding: '10px 20px 0 20px',
    maxHeight: 'unset'
  },
  cardActionArea: {
    textAlign: 'center',
    padding: '6px 5px 0 5px',
  },
  cardAction: {
    padding: '0 10px',
    '& button': {
      padding: 6,
    },
  },
  cardContentDetails: {
    padding: '0 20px 5px 20px !important',
    fontSize: '0.8rem',
  },
  pin: {
    '&.MuiSvgIcon-root': {
      fontSize: '1.3rem',
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
  cardType: {
    fontSize: 14,
  },
  subtitle: {
    fontSize: 10,
  },
  content: {
    fontSize: 14,
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
    '& :hover': {
      cursor: 'pointer',
    },
    '& button': {
      padding: 0,
      marginLeft: 4,
    },
  },
  address: {
    fontSize: 13,
    marginBottom: 12,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  hr: {
    backgroundColor: 'lightgrey',
    height: 1,
    border: 0,
  },
  rightIcon: {
    marginLeft: 'auto'
  },
  tabs: {
    maxWidth: '100%'
  },
  checked: {
    color: 'var(--fill-color)',
  },
  track: {
    backgroundColor: 'var(--fill-color)'
  },
  progress: {
    margin: 12,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  headerActions: {
    display: 'flex',
  },
  headerTitle: {
    flexGrow: 2,
  },
  colorPrimary: {
    color: 'var(--fill-color)'
  },
  row: {
    lineHeight: 1,
    // override card actions margin issue
    '&:not(:first-child)': {
      marginLeft: 0
    },
    marginLeft: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      color: 'black',
    }
  },
  gridContent: {
    alignSelf: 'center',
    '& .MuiTypography-overline': {
      lineHeight: '24px',
      height: 24,
      fontSize: '0.7rem',
    },
    '& .MuiButton-root': {
      fontSize: '0.7rem',
    }
  },
  accordionBtn: {
    padding: '0 4px',
    minWidth: 0,
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  floors: {
    maxHeight: 115,
    padding: 0,
    borderTop: '1px solid rgba(0,0,0,0.1)',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      webkitAppearance: 'none',
      width: 7,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
    marginRight: 20,
    width: '100%'
  },
  floorActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  linearProgress: {
    width: '100%',
    height: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

export default function BuildingDisplay({ canZoom, buildingData }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const buildingObj = buildingSort(buildingData);

  const handleZoom = (bbox, floor) => {
    dispatch(actionMapZoomFeaturesUpdated({
      type: 'bbox',
      data: bbox,
    }));
    if (floor) {
      dispatch(actionAppBarFloorUpdated(floor));
    }
    // trackEvent({
    //   category: SPREADSHEET,
    //   action: 'Zoom to Floor',
    //   label: floor
    // });
  }

  if (!canZoom || !buildingObj) {
    return (
      <div className={classes.linearProgress}>
        <LinearProgress color="primary" />
      </div>
    );
  }

  return (
    <div className={classes.floors}>
      {
        Object.keys(buildingObj).map(building => (
          <Grid key={building} container align="center" justifyContent="center" className={classes.row}>
            <Grid item xs={4} className={classes.gridContent}>
              <Typography variant="overline">
                {building}
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.gridContent}>
              {buildingObj[building].map(({ bbox, floor }) => {
                const zoomFloor = (floor === 'No Floor' ? null : floor);
                return (
                  <Button
                    size="small"
                    color="primary"
                    className={classes.accordionBtn}
                    onClick={() => handleZoom(bbox, zoomFloor)}
                    key={floor || 'No Floor'}
                  >
                    {floor || 'No Floor'}
                  </Button>
                );
              })}
            </Grid>
          </Grid>
        ))
      }
    </div>
  );
}

function buildingSort(buildingData) {
  if (!buildingData) return;
  const returnObj = {};

  buildingData.forEach(({ data, bbox }) => {
    if (!returnObj[data.building]) {
      returnObj[data.building] = [{
        floor: data.floor,
        bbox
      }];
    } else {
      returnObj[data.building].push({
        floor: data.floor,
        bbox
      });
    }
  });

  return returnObj;
}