import { actionSharedDatasetSelectLayer } from ".";

export const handleSharedDataClick = (features, dispatch) => {
  if (
    !features || 
    !Array.isArray(features) ||
    features.length === 0 ||
    !features.some(x => x.layer.id.indexOf('shared') === 0)
  ) {
    dispatch(actionSharedDatasetSelectLayer({}));
    return;
  }

  const sharedFeaturesArray = features.filter(x => x.layer.id.indexOf('shared') === 0)  
  const mapLayerId = sharedFeaturesArray[0].layer.id;
  const rrn = sharedFeaturesArray[0].id;

  let stringIndexfirst;
  if (mapLayerId.indexOf('shared-fill-') === 0) {
    stringIndexfirst = 'shared-fill-'.length;
  }
  if (mapLayerId.indexOf('shared-line-') === 0) {
    stringIndexfirst = 'shared-line-'.length;
  }
  if (mapLayerId.indexOf('shared-symbol-') === 0) {
    stringIndexfirst = 'shared-symbol-'.length;
  }
  const layerId = mapLayerId.slice(stringIndexfirst)
  dispatch(actionSharedDatasetSelectLayer({
    driveId: layerId,
    rrn
  }))


}