// const fetchApiUrl = "https://kv9n1px627.execute-api.us-east-1.amazonaws.com/dev";
const fetchApiUrl = "https://kv9n1px627.execute-api.us-east-1.amazonaws.com/dev";
// const fetchApiUrl = "https://4wcpxqxdf7.execute-api.us-east-1.amazonaws.com/stage";
// const fetchApiUrl = "https://28uk6zozp0.execute-api.us-east-1.amazonaws.com/prod";

// const fetchApiUrl = process.env.REACT_APP_GDRIVE_API;
// console.log(fetchApiUrl)

export const fetchFileContent = async (fileId, idToken) => {
  const fileFetchEndpoint = `${fetchApiUrl}/file`; // just file content
  const getFileContentUrl = `${fileFetchEndpoint}/${fileId}?idToken=${encodeURIComponent(idToken)}`;
  try {
    const response = await fetch(getFileContentUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // const content = await response.text(); // or `.json()` if the response is JSON
    const content = await response.json(); // or `.json()` if the response is JSON
    return content;
  } catch (e) {
    console.error('Failed to fetch file content: ' + e.message);
    throw e;
  }
};

export const fetchSharedData = async (idToken) => {
  const listingApiUrl = `${fetchApiUrl}/list?idToken=${encodeURIComponent(idToken)}`;

  try {
    const response = await fetch(listingApiUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data.files; // Assuming the API returns an object with a files array
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const openGoogleSheet = (id) => {
  const url = `https://docs.google.com/spreadsheets/d/${id}`
  window.open(url, '_blank');
};

// TODO test out Geometry later
// const fetchFileWithGeometry = async (fileId) => {
//   const fileFetchEndpoint = `${fetchApiUrl}/room`; // with room geometry
//   const getFileContentUrl = `${fileFetchEndpoint}/${fileId}?idToken=${encodeURIComponent(idToken)}`;
//   try {
//     const response = await fetch(getFileContentUrl);
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     const content = await response.text(); // or `.json()` if the response is JSON
//     return content;
//   } catch (e) {
//     console.error(e.message)
//     throw e;
//   }
// };